//
// Component: Social Widgets
//

//General widget style
.card-widget {
  border: 0;
  position: relative;
}

//User Widget Style 1
.widget-user {

  //User name container
  .widget-user-header {
    @if $enable-rounded {
      @include border-top-radius($border-radius);
    }

    height: 135px;
    padding: 1rem;
    text-align: center;
  }

  //User name
  .widget-user-username {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
    text-shadow: 0 1px 1px rgba($black, .2);
  }

  //User single line description
  .widget-user-desc {
    margin-top: 0;
  }

  //User image container
  .widget-user-image {
    left: 50%;
    margin-left: -45px;
    position: absolute;
    top: 80px;

    > img {
      border: 3px solid $white;
      height: auto;
      width: 90px;
    }
  }

  .card-footer {
    padding-top: 50px;
  }
}

//User Widget Style 2
.widget-user-2 {

  //User name container
  .widget-user-header {
    @include border-top-radius($border-radius);
    padding: 1rem;
  }

  //User name
  .widget-user-username {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  //User single line description
  .widget-user-desc {
    margin-top: 0;
  }

  .widget-user-username,
  .widget-user-desc {
    margin-left: 75px;
  }

  //User image container
  .widget-user-image {
    > img {
      float: left;
      height: auto;
      width: 65px;
    }
  }
}
